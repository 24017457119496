import { HttpError } from '~/errors/httpError';
import { rentalApplicationGateway } from '~/gateways/rentalApplicationGateway';
import {
  EmploymentHistoryErrorCode,
  FileUploadResult,
  RentalApplicationErrorCode,
  RentalHistoryErrorCode,
} from '~/types/RentalApplication';
import { MagicUseCase } from '~/use-cases/magicUseCase';

enum ValidationErrorType {
  PHONE = 'phone',
  FIRST_NAME = 'firstname',
  LAST_NAME = 'lastname',
  EMAIL = 'email',
  DATE_OF_BIRTH = 'dateofbirth',
  SSN = 'ssn',
  LANDLORD_NAME = 'landlordname',
  LANDLORD_PHONE = 'landlordphone',
  RENT = 'rent',
  MOVE_IN_DATE = 'moveindate',
  MOVE_OUT_DATE = 'moveoutdate',
  REASON_FOR_LEAVING = 'reasonforleaving',
  STREET_ADDRESS_1 = 'address.streetaddress1',
  STREET_ADDRESS_2 = 'address.streetaddress2',
  CITY = 'address.city',
  STATE = 'address.state',
  ZIP_CODE = 'address.zipcode',
  COUNTRY = 'address.country',
  NAME = 'name',
  START_DATE = 'startdate',
  POSITION = 'position',
  EMPLYMENT_PHONE = 'phone',
  RESIDENTIAL_HISTORY = 'residentialhistory',
  EMPLOYMENT = 'employment',
}

export class SaveRentalApplicationUseCase extends MagicUseCase {
  private rentalApplicationErrors: RentalApplicationErrorCode[] = [];
  private rentalHistoryErrors: RentalHistoryErrorCode[][] = [];
  private employmentHistoryErrors: EmploymentHistoryErrorCode[][] = [];

  protected runLogic = async () => {
    const application = this.getState().user.rentalApplication.application;
    if (!application || !application?.isDraft) {
      return;
    }
    let progress = 0;
    this.onProgress?.(progress);
    const files = this.getState().user.rentalApplication.application?.newFiles?.filter((file) => !file.wasUploaded);
    const delta = files ? 100.0 / (files.length + 1) : 100;
    if (files) {
      for (const file of files) {
        const result: FileUploadResult = await rentalApplicationGateway.uploadRentalApplicationFile(file);
        application.files?.push({
          fileId: result.fileId,
          fileUrl: result.signedUrl,
          thumbUrl: result.signedUrl,
          type: file.type,
          fileName: file.name,
          fileExtension: '',
          contentType: '',
          fileSize: 0,
        });
        progress = Math.round(progress + delta);
        file.wasUploaded = true;
        this.onProgress?.(progress);
      }
    }
    try {
      const newApplication = await rentalApplicationGateway.updateApplication(application);
      progress += delta;
      this.getState().user.rentalApplication.application = newApplication;
    } catch (error: any) {
      this.handleError(error);
    }
    this.onProgress?.(progress);
  };

  private handleError = (error: any) => {
    if (!(error instanceof HttpError || !error.getErrors())) {
      throw error;
    }
    const errors = error.getErrors();
    if (!errors) {
      throw error;
    }
    const keys = Object.keys(errors);
    this.getState().user.rentalApplication.application?.residentialHistory?.forEach(() => {
      this.rentalHistoryErrors.push([]);
    });
    this.getState().user.rentalApplication.application?.employmentHistory?.forEach(() => {
      this.employmentHistoryErrors.push([]);
    });
    keys.forEach((key: string) => {
      const sanitizedKey = key.replace(/^\$\./, '').toLowerCase();
      this.handleApplicantErrors(sanitizedKey);
      const components = this.splitErrorString(sanitizedKey);
      if (components?.prefix === ValidationErrorType.RESIDENTIAL_HISTORY) {
        this.handleRentalHistoryErrors(components.index, components.suffix);
      } else if (components?.prefix === ValidationErrorType.EMPLOYMENT) {
        this.handleEmploymentErrors(components.index, components.suffix);
      }
    });
    this.getState().user.rentalApplication.errors = this.rentalApplicationErrors;
    this.getState().user.rentalApplication.rentalHistoryErrors = this.rentalHistoryErrors;
    this.getState().user.rentalApplication.employmentHistoryErrors = this.employmentHistoryErrors;
    if (this.noErrorsWereRecognized()) {
      throw error;
    }
  };

  private handleApplicantErrors = (errorType: string) => {
    if (errorType === ValidationErrorType.PHONE) {
      this.rentalApplicationErrors.push(RentalApplicationErrorCode.InvalidPhone);
    }
    if (errorType === ValidationErrorType.FIRST_NAME) {
      this.rentalApplicationErrors.push(RentalApplicationErrorCode.InvalidFirstName);
    }
    if (errorType === ValidationErrorType.LAST_NAME) {
      this.rentalApplicationErrors.push(RentalApplicationErrorCode.InvalidLastName);
    }
    if (errorType === ValidationErrorType.EMAIL) {
      this.rentalApplicationErrors.push(RentalApplicationErrorCode.InvalidEmail);
    }
    if (errorType === ValidationErrorType.DATE_OF_BIRTH) {
      this.rentalApplicationErrors.push(
        RentalApplicationErrorCode.InvalidDateOfBirth ||
          RentalApplicationErrorCode.InvalidDateOfBirthGreater ||
          RentalApplicationErrorCode.InvalidDateOfBirthLesser
      );
    }
    if (errorType === ValidationErrorType.SSN) {
      this.rentalApplicationErrors.push(RentalApplicationErrorCode.InvalidSSN);
    }
  };

  private handleRentalHistoryErrors = (index: number, errorType: string) => {
    let errorCode: RentalHistoryErrorCode | undefined;

    if (errorType === ValidationErrorType.LANDLORD_NAME) {
      errorCode = RentalHistoryErrorCode.InvalidRentalHistoryLandlordName;
    }

    if (errorType === ValidationErrorType.LANDLORD_PHONE) {
      errorCode = RentalHistoryErrorCode.InvalidRentalHistoryLandlordPhone;
    }

    if (errorType === ValidationErrorType.RENT) {
      errorCode = RentalHistoryErrorCode.InvalidRentalHistoryRent;
    }

    if (errorType === ValidationErrorType.MOVE_IN_DATE) {
      errorCode = RentalHistoryErrorCode.InvalidRentalHistoryMoveInDate;
    }

    if (errorType === ValidationErrorType.MOVE_OUT_DATE) {
      errorCode = RentalHistoryErrorCode.InvalidRentalHistoryMoveOutDate;
    }

    if (errorType === ValidationErrorType.REASON_FOR_LEAVING) {
      errorCode = RentalHistoryErrorCode.InvalidRentalHistoryReasonForLeaving;
    }

    if (errorType === ValidationErrorType.STREET_ADDRESS_1) {
      errorCode = RentalHistoryErrorCode.InvalidRentalHistoryStreetAddress1;
    }

    if (errorType === ValidationErrorType.STREET_ADDRESS_2) {
      errorCode = RentalHistoryErrorCode.InvalidRentalHistoryStreetAddress2;
    }

    if (errorType === ValidationErrorType.STATE) {
      errorCode = RentalHistoryErrorCode.InvalidRentalHistoryState;
    }

    if (errorType === ValidationErrorType.CITY) {
      errorCode = RentalHistoryErrorCode.InvalidRentalHistoryCity;
    }

    if (errorType === ValidationErrorType.ZIP_CODE) {
      errorCode = RentalHistoryErrorCode.InvalidRentalHistoryZipCode;
    }

    if (errorType === ValidationErrorType.COUNTRY) {
      errorCode = RentalHistoryErrorCode.InvalidRentalHistoryCountry;
    }

    if (errorCode) {
      this.rentalHistoryErrors[index].push(errorCode);
    }
  };

  private handleEmploymentErrors = (index: number, errorType: string) => {
    if (errorType === ValidationErrorType.NAME) {
      this.employmentHistoryErrors[index].push(EmploymentHistoryErrorCode.InvalidNameError);
    }
    if (errorType === ValidationErrorType.START_DATE) {
      this.employmentHistoryErrors[index].push(EmploymentHistoryErrorCode.InvalidStartDateError);
    }
    if (errorType === ValidationErrorType.POSITION) {
      this.employmentHistoryErrors[index].push(EmploymentHistoryErrorCode.InvalidPositionError);
    }
    if (errorType === ValidationErrorType.EMPLYMENT_PHONE) {
      this.employmentHistoryErrors[index].push(EmploymentHistoryErrorCode.InvalidPhoneError);
    }
  };

  private noErrorsWereRecognized = (): boolean => {
    return (
      this.rentalApplicationErrors.length === 0 && this.isEmpty(this.rentalHistoryErrors) && this.isEmpty(this.employmentHistoryErrors)
    );
  };

  private splitErrorString(input: string): { prefix: string; index: number; suffix: string } | undefined {
    const match = input.match(/^([^[\]]+)\[(\d+)\]\.(.+)/);
    if (match) {
      const prefix = match[1];
      const index = parseInt(match[2], 10);
      const suffix = match[3];
      return { prefix, index, suffix };
    }
    return undefined;
  }

  private isEmpty = (twoDArray: any[][]): boolean => {
    for (const array of twoDArray) {
      if (array.length > 0) {
        return false;
      }
    }
    return true;
  };
}
