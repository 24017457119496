import { GoToNextStepRequest } from '~/types/GoToNextStepRequest';
import { RentalApplicationSteps } from '~/types/RentaApplicationSteps';
import { MagicUseCase } from '../magicUseCase';
import { SaveRentalApplicationUseCase } from './application-steps/saveRentalApplicationUseCase';
import { SetCurrentStepUseCase } from './application-steps/setCurrentStepUseCase';

export class GoToNextStepUseCase extends MagicUseCase {
  protected async runLogic(request: GoToNextStepRequest) {
    const application = this.getState().user.rentalApplication.application;
    if (
      application &&
      !application.isDraft &&
      !this.getState()?.user.rentalApplication.isPaid &&
      this.getState().user.rentalApplication.settings?.requiresPayment
    ) {
      await new SetCurrentStepUseCase().execute(RentalApplicationSteps.PAYMENT);
      this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/payment`);
      return;
    }
    if (application?.isDraft) {
      await new SaveRentalApplicationUseCase().execute(request.credentials);
    }
    switch (request.currentStep) {
      case RentalApplicationSteps.APPLICANT_INFORMATION: {
        await new SetCurrentStepUseCase().execute(RentalApplicationSteps.POTENTIAL_UNITS);
        this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/potential-units`);
        break;
      }
      case RentalApplicationSteps.POTENTIAL_UNITS: {
        await new SetCurrentStepUseCase().execute(RentalApplicationSteps.RENTAL_HISTORY);
        this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/rental-history`);
        break;
      }
      case RentalApplicationSteps.RENTAL_HISTORY: {
        await new SetCurrentStepUseCase().execute(RentalApplicationSteps.WORK_HISTORY);
        this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/employment-income`);
        break;
      }
      case RentalApplicationSteps.WORK_HISTORY: {
        const questions = this.getState().user.rentalApplication.settings?.questions;
        if (questions && questions.length > 0) {
          await new SetCurrentStepUseCase().execute(RentalApplicationSteps.QUESTIONS);
          this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/rental-questions`);
        } else {
          await new SetCurrentStepUseCase().execute(RentalApplicationSteps.TERMS_AND_CONDITIONS);
          this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/terms-and-conditions`);
        }
        break;
      }
      case RentalApplicationSteps.QUESTIONS: {
        await new SetCurrentStepUseCase().execute(RentalApplicationSteps.TERMS_AND_CONDITIONS);
        this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/terms-and-conditions`);
        break;
      }
      case RentalApplicationSteps.TERMS_AND_CONDITIONS: {
        if (!this.getState()?.user.rentalApplication.isPaid && this.getState()?.user.rentalApplication.settings?.requiresPayment) {
          await new SetCurrentStepUseCase().execute(RentalApplicationSteps.PAYMENT);
          this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/payment`);
        } else if (this.getState().user.rentalApplication.settings?.requiresScreening) {
          await new SetCurrentStepUseCase().execute(RentalApplicationSteps.TRANS_UNION_SCREENING);
          this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/transunion-connection`);
        } else {
          await new SetCurrentStepUseCase().execute(RentalApplicationSteps.APPLICATION_SUBMITTED);
          this.navigate(`/rental-applications/${request.credentials.id}/${request.credentials.password}/application-submitted`);
        }
        break;
      }
    }
  }
}
