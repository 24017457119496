import { Component, Show } from 'solid-js';
import { NEXT, PREVIOUS } from '~/assets/strings';
import { Button } from '~/components/common/buttons';
import { useLocalization } from '~/hooks/useLocalization';

export const StepFooter: Component<{
  onNext: () => void;
  onPrevious?: () => void;
}> = (props) => {
  const { t } = useLocalization();
  return (
    <div class="fixed bottom-0 left-0 z-20 w-full border-t border-gray-200 bg-white px-4 py-3">
      <div class="flex justify-end gap-4">
        <Show when={props.onPrevious}>
          <Button type="button" onClick={props.onPrevious}>
            {t(PREVIOUS)}
          </Button>
        </Show>
        <Button type="button" onClick={props.onNext}>
          {t(NEXT)}
        </Button>
      </div>
    </div>
  );
};
